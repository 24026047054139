import {
	Typography,
	Stack,
	Paper,
	ButtonBase
} from '@mui/material';
import theme from '../components/MaterialTheme';
import Separator from './Separator';
import ButtonComp from '../components/button';
import MaterialTheme from '../components/MaterialTheme';
import { useNavigate } from 'react-router-dom';

/**
 * Displayed when there are no search results to display.
 */
export function NoResults() {
	const navigate = useNavigate();

	return (
		<Paper
			sx={{
				flexGrow: 1,
				backgroundColor: MaterialTheme.palette.grey.A100,
				borderRadius: '0 12px 12px 0',
				p: 2,
				boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
				margin: '10px',
			}}
		>
			<Stack
				direction='column'
				sx={{
					marginLeft: '58px',
					marginRight: '58px',
					alignItems: 'center',
					[theme.breakpoints.down('md')]: {
						marginLeft: 0,
						marginRight: 0,
					},
				}}
			>
				<Typography variant='h4' sx={{ textAlign: 'center' }}>
					Sorry, there are no search results to match your filters. We are currently only searching registered nonprofit websites in select states.
				</Typography>
				<Separator primary={true} />
				<ButtonBase>
					<ButtonComp
						size="medium"
						hoverStyle="light"
						bgStyle="secondary"
						onClick={() =>navigate('/search')}
					>
						<Typography variant="h4">
							Reset Search
						</Typography>
					</ButtonComp>
				</ButtonBase>
			</Stack>
		</Paper>
	);
}

import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputBase,
  Slider,
  Stack,
  SwipeableDrawer,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  Input,
  Box,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import React, { useEffect, useState } from "react";
import { Icons } from "./Icons";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FilterOptions } from "../data/type";
import dayjs, { Dayjs } from "dayjs";
import { JSX } from "react/jsx-runtime";
import { useFilterMaps } from "../hooks/useFilterMaps";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { URLSearchParams } from "url";
import { useDebouncedCallback } from "use-debounce";
import { SearchBar } from "./SearchBar";
import MaterialTheme from "./MaterialTheme";
import { useResults } from "../hooks/useResults";
import { InfoRounded } from "@mui/icons-material";

const CustomAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

interface FilterChipProps {
  label: string;
  onRemove: () => void;
  invalidLocation?: boolean;
  type?: "location" | "skills" | "causes";
}

const FilterChip: React.FC<FilterChipProps> = ({
  label,
  onRemove,
  invalidLocation = false,
  type,
}) => {
  const color =
    type === "location" && invalidLocation
      ? MaterialTheme.palette.error.main
      : MaterialTheme.palette.primary.main;

  return (
    <Tooltip title={invalidLocation ? "Invalid location" : ""}>
      <Stack
        key={label}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          color,
          borderRadius: "15px",
          border: `1px solid ${color}`,
          padding: "5px 10px",
          marginRight: "10px",
          marginBottom: "10px",
          cursor: "pointer",
          maxWidth: "100%",
          overflow: "hidden",
          width: "fit-content",
        }}
      >
        <Typography variant="body2">{label}</Typography>
        <IconButton
          size="small"
          sx={{
            marginLeft: "5px",
            color,
            height: "20px",
          }}
          onClick={onRemove}
          aria-label={`Remove ${label} filter`}
        >
          <Icons.Close />
        </IconButton>
      </Stack>
    </Tooltip>
  );
};

const InfoChip: React.FC<FilterChipProps> = ({ label, onRemove }) => (
  <Stack
    key={label}
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    sx={{
      backgroundColor: MaterialTheme.palette.primary.main,
      color: MaterialTheme.palette.primary.contrastText,
      borderRadius: "5px",
      padding: "5px 10px",
      marginRight: "10px",
      marginBottom: "10px",
      cursor: "pointer",
    }}
  >
    <Typography variant="body2">{label}</Typography>
  </Stack>
);

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const marks = [
  {
    value: 5,
    label: "5 mi",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 15,
    label: "15",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 30,
    label: "30",
  },
];

const NAVBAR_HEIGHT = 75;
export const DEFAULT_DISTANCE = 20;

function valuetext(value: number) {
  return `${value} miles`;
}

function getDistanceString(distance: string | null) {
  return distance ? ` (${distance} mi)` : "";
}

interface StateParams {
  key: keyof FilterOptions;
  value: string;
  query: URLSearchParams;
}

interface FilterProps {
  filterEnabled: boolean;
  setFilterEnabled: (value: boolean) => void;
  queryKey: () => string;
}

const Filter: React.FC<FilterProps> = ({
  filterEnabled,
  setFilterEnabled,
  queryKey,
}) => {
  const [expanded, setExpanded] = React.useState<string | false>("");
  const { data: results } = useResults(queryKey());

  const isMobile = useMediaQuery(MaterialTheme.breakpoints.down("md"));
  const [isClosing, setIsClosing] = useState(false);
  const [distance, setDistance] = useState(20);

  const uniqueAges = results
    ? [...new Set(results.results.map((r) => r.vo_min_age))].sort()
    : [];

  const handleDrawerClose = () => {
    setIsClosing(true);
    setFilterEnabled(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setFilterEnabled(!filterEnabled);
    }
  };

  // TODO: use isLoading and isError to show loading and error states (if needed)
  // Keep them commented or delete to avoid additional re-renders.
  const { filterMaps /** , isLoading, isError  */ } = useFilterMaps();

  const causes = filterMaps ? filterMaps.cause : [];
  const skills = filterMaps ? filterMaps.skill : [];

  const [query, setSearchParams] = useSearchParams();

  // To ensure the user can fluidly type into the location input while we debounce the querym
  // we need to manage the userLocation state separately.
  const [userLocation, setUserLocation] = useState<string | null>(
    query.get("location"),
  );

  useEffect(() => {
    debounced(userLocation);
  }, [userLocation]);

  useEffect(() => {
    const loc = query.get("location");
    const dist = query.get("distance");
    if (loc) {
      setUserLocation(loc);
    }
    if (dist) {
      setDistance(Number(dist));
    }
  }, [query]);

  /**
   * Utility function to manage query params and keep state in sync.
   * Appends a key-value pair to the query params.
   */
  const appendQuery = ({ key, value, query }: StateParams) => {
    query.append(key, value);
    setSearchParams(query);
  };

  /**
   * Utility function to manage query params and keep state in sync.
   * Sets (or replaces) a key-value pair to the query params.
   */
  const setQuery = ({ key, value, query }: StateParams) => {
    query.set(key, value);
    setSearchParams(query);
  };

  /**
   * Utility function to manage query params and keep state in sync.
   * Deletes a key-value pair from the query params.
   * If a value is provided, only the parameter with the matching value is removed.
   */
  const deleteQuery = ({
    key,
    value,
    query,
  }: {
    key: keyof FilterOptions;
    value?: string;
    query: globalThis.URLSearchParams;
  }) => {
    if (value == null) {
      query.delete(key);
    } else {
      const values = query.getAll(key);
      query.delete(key);
      values.forEach((val) => {
        if (val !== value) {
          query.append(key, val);
        }
      });
    }
    setSearchParams(query);
  };

  const drawerIcons = {
    panel1:
      expanded != "panel1" ? <ExpandCircleDownIcon /> : <ExpandLessIcon />,
    panel2:
      expanded != "panel2" ? <ExpandCircleDownIcon /> : <ExpandLessIcon />,
    panel3:
      expanded != "panel3" ? <ExpandCircleDownIcon /> : <ExpandLessIcon />,
    panel4:
      expanded != "panel4" ? <ExpandCircleDownIcon /> : <ExpandLessIcon />,
    panel5:
      expanded != "panel5" ? <ExpandCircleDownIcon /> : <ExpandLessIcon />,
    panel6:
      expanded != "panel6" ? <ExpandCircleDownIcon /> : <ExpandLessIcon />,
  };

  // Function to remove a filter chip
  const handleRemoveFilter = (
    filterType: keyof FilterOptions,
    value: string,
  ) => {
    deleteQuery({ key: filterType, value, query });
  };

  // Function to handle changes in location
  const handleLocationChange = (value: string) => {
    if (value) {
      query.set("location", value);
      query.set("distance", query.get("distance") ?? `${DEFAULT_DISTANCE}`);
      setSearchParams(query);
    } else {
      deleteQuery({ key: "location", query });
    }
  };

  const debounced = useDebouncedCallback(
    // function
    (value) => {
      handleLocationChange(value);
    },
    // delay in ms
    333,
  );

  const resetLocation = () => {
    setUserLocation(null);
    query.delete("location");
    query.delete("distance");
    setSearchParams(query);
  };

  // Function to handle changes in distance
  const handleDistanceChange = (newValue: number) => {
    setQuery({ key: "distance", value: `${newValue}`, query });
  };

  const debouncedRadius = useDebouncedCallback(
    // function
    (value) => {
      handleDistanceChange(value);
    },
    // delay in ms
    333,
  );

  const handleClearFilters = () => {
    setUserLocation("");
    // Clear all selected filters. Note that query.forEach had some issues.
    query.delete("keywords");
    query.delete("causes");
    query.delete("skills");
    query.delete("startDate");
    query.delete("endDate");
    query.delete("preference");
    query.delete("location");
    query.delete("distance");
    setSearchParams(query);
  };

  const filtersSelected =
    query.has("startDate") ||
    query.has("endDate") ||
    query.getAll("causes").length !== 0 ||
    query.getAll("skills").length !== 0 ||
    query.has("minAge");

  const renderFilterChips = () => {
    const filterChips: JSX.Element[] = [];

    // Render cause filter chips
    query.getAll("causes").forEach((key) => {
      filterChips.push(
        <FilterChip
          key={key}
          label={`${key}`}
          onRemove={() => handleRemoveFilter("causes", key)}
        />,
      );
    });

    // Render skill filter chips
    query.getAll("skills").forEach((key) => {
      filterChips.push(
        <FilterChip
          key={key}
          label={`${key}`}
          onRemove={() => handleRemoveFilter("skills", key)}
        />,
      );
    });

    if (query.has("minAge")) {
      const noReq = !uniqueAges.includes(
        parseInt(query.get("minAge") ?? "1000")
      );

      filterChips.push(
        <FilterChip
          key="minAge"
          label={
            noReq ? "No Minimum Age" : `Minimum Age: ${query.get("minAge")}+`
          }
          onRemove={() => {
            deleteQuery({ key: "minAge", query });
          }}
        />
      );
    }
    return filterChips;
  };

  const handleMultiCheckboxChange = (
    key: "skills" | "causes",
    value: string,
  ) => {
    if (query.getAll(key).includes(value)) {
      deleteQuery({ key, value, query });
    } else {
      appendQuery({ key, value, query });
    }
  };

  const causeCheckboxes = causes.map((cause) => (
    <FormControlLabel
      control={
        <Checkbox
          onChange={() => handleMultiCheckboxChange("causes", cause)}
          checked={query.getAll("causes").includes(cause)}
        />
      }
      label={cause}
      value={cause}
      key={cause}
    />
  ));

  const skillCheckboxes = skills.map((skill) => (
    <FormControlLabel
      control={
        <Checkbox
          onChange={() => handleMultiCheckboxChange("skills", skill)}
          checked={query.getAll("skills").includes(skill)}
        />
      }
      label={skill}
      value={skill}
      key={skill}
    />
  ));

  const handleAccordianExpansion =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const drawerWidth = isMobile ? "100%" : "25%";

  return (
    <SwipeableDrawer
      variant={isMobile ? "temporary" : "persistent"}
      anchor={isMobile ? "bottom" : "left"}
      open={isMobile ? filterEnabled : true}
      onTransitionEnd={handleDrawerTransitionEnd}
      onOpen={handleDrawerToggle}
      onClose={handleDrawerClose}
      sx={{
        zIndex: MaterialTheme.zIndex.drawer,
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Stack
        gap={1}
        flex={5}
        mt={isMobile ? 0 : `${NAVBAR_HEIGHT}px`}
        pt={isMobile ? 0 : 2}
        pb={isMobile ? 2 : 0}
        height={isMobile ? "100%" : "auto"}
        bgcolor={MaterialTheme.palette.grey[200]}
        width="100%"
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          px={2}
          bgcolor={
            isMobile ? MaterialTheme.palette.primary.light : "transparent"
          }
        >
          {/* Use Filters if no filters selected otherwise Selected Filters */}
          <Typography variant="h3" pt={isMobile ? 2 : 0} pb={isMobile ? 2 : 0}>
            Filter
          </Typography>
          {filtersSelected && !isMobile && (
            <Button
              onClick={handleClearFilters}
              sx={{
                color: "rgba(56, 106, 152, 1)",
                textTransform: "none",
              }}
            >
              {"Clear"}
            </Button>
          )}
          {isMobile && (
            <IconButton
              area-label="close filter drawer"
              onClick={() => setFilterEnabled(false)}
            >
              <Icons.Close />
            </IconButton>
          )}
        </Stack>

        {filtersSelected && (
          <Stack direction={"column"}>
            {isMobile && (
              <Button
                onClick={handleClearFilters}
                sx={{
                  alignSelf: "flex-start",
                  color: "rgba(56, 106, 152, 1)",
                  textTransform: "none",
                }}
              >
                {"Clear"}
              </Button>
            )}
            <Stack direction={"row"} flexWrap={"wrap"} px={isMobile ? 1 : 2}>
              {renderFilterChips()}
            </Stack>
          </Stack>
        )}

        <Stack>
          {/* ---------------- CAUSES ---------------- */}
          <CustomAccordion
            expanded={expanded === "panel1"}
            onChange={handleAccordianExpansion("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              sx={{
                "& .MuiAccordionSummary-content": { alignItems: "center" },
              }}
            >
              <Typography variant="h5">Causes</Typography>
              <IconButton
                size="small"
                /* Center it and keep it to the left */
                sx={{ marginLeft: "auto" }}
              >
                {drawerIcons.panel1}
              </IconButton>
            </AccordionSummary>
            <CustomAccordionDetails
              sx={{
                maxHeight: "13rem", // Set a maximum height
                overflowY: "auto", // Enable vertical scrolling
              }}
            >
              <Stack>
                <FormGroup>
                  {causeCheckboxes.map((checkbox) => checkbox)}
                </FormGroup>
              </Stack>
            </CustomAccordionDetails>
          </CustomAccordion>

          {/* ---------------- SKILLS ---------------- */}
          <CustomAccordion
            expanded={expanded === "panel2"}
            onChange={handleAccordianExpansion("panel2")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
              sx={{
                "& .MuiAccordionSummary-content": { alignItems: "center" },
              }}
            >
              <Typography variant="h5">Skills</Typography>
              <IconButton
                size="small"
                /* Center it and keep it to the left */
                sx={{ marginLeft: "auto" }}
              >
                {drawerIcons.panel2}
              </IconButton>
            </AccordionSummary>
            <CustomAccordionDetails
              sx={{
                maxHeight: "13rem", // Set a maximum height
                overflowY: "auto", // Enable vertical scrolling
              }}
            >
              <Stack>
                <FormGroup>
                  {skillCheckboxes.map((checkbox) => checkbox)}
                </FormGroup>
              </Stack>
            </CustomAccordionDetails>
          </CustomAccordion>

          {/* ---------------- LOCATION and DISTANCE ---------------- */}
          <CustomAccordion
            expanded={expanded === "panel3"}
            onChange={handleAccordianExpansion("panel3")}
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
              sx={{
                "& .MuiAccordionSummary-content": { alignItems: "center" },
              }}
            >
              <Typography variant="h5">Location</Typography>
              <IconButton
                size="small"
                /* Center it and keep it to the left */
                sx={{ marginLeft: "auto" }}
              >
                {drawerIcons.panel3}
              </IconButton>
            </AccordionSummary>
            <CustomAccordionDetails>
              <Stack gap={2}>
                <Stack
                  direction={"row"}
                  sx={{
                    backgroundColor: MaterialTheme.palette.grey[200],
                    borderRadius: "5px",
                  }}
                >
                  <IconButton
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="directions"
                  >
                    <Icons.Pin />
                  </IconButton>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Enter the location"
                    inputProps={{ "aria-label": "Enter the location" }}
                    // Make sure to include empty string as a value fallback for proper behavior.
                    value={userLocation ?? ""}
                    onChange={(e) => {
                      if (e.target.value) {
                        setUserLocation(e.target.value);
                      }
                    }}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="Clear location"
                    onClick={() => setUserLocation("")}
                  >
                    <Icons.Close />
                  </IconButton>
                </Stack>
                <Typography variant="body1">
                  Distance Radius: {distance}mi
                </Typography>
                <Stack alignItems={"center"}>
                  <Slider
                    sx={{ width: "85%" }}
                    aria-label="Distance"
                    defaultValue={DEFAULT_DISTANCE}
                    getAriaValueText={valuetext}
                    marks={marks}
                    min={1}
                    max={30}
                    value={distance}
                    onChange={(event: Event, newValue: number | number[]) => {
                      if (typeof newValue === "number") {
                        setDistance(newValue);
                        debouncedRadius(newValue);
                      }
                    }}
                  />
                </Stack>
              </Stack>
            </CustomAccordionDetails>
          </CustomAccordion>
          <CustomAccordion
            expanded={expanded === "panel6"}
            onChange={handleAccordianExpansion("panel6")}
          >
            <AccordionSummary
              aria-controls="panel5d-content"
              id="panel5d-header"
              sx={{
                "& .MuiAccordionSummary-content": { alignItems: "center" },
              }}
            >
              <Typography variant="h5">Minimum Age</Typography>
              <IconButton
                size="small"
                /* Center it and keep it to the left */
                sx={{ marginLeft: "auto" }}
              >
                {drawerIcons.panel6}
              </IconButton>
            </AccordionSummary>
            <CustomAccordionDetails>
              {results && (
                <Stack width={"75%"} gap={1}>
                  <Stack bgcolor={"white"} borderRadius={1}>
                    <FormGroup>
                      {uniqueAges.sort().map((age, i, ages) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e, checked) => {
                                if (i === ages.length - 1 && checked) {
                                  deleteQuery({ key: "minAge", query });
                                } else {
                                  const ageIdx = Math.max(i - 1, 0);
                                  setQuery({
                                    key: "minAge",
                                    value:
                                      (ages[ageIdx] === 0 || ageIdx === 0) &&
                                        !checked
                                        ? (age - 1).toString()
                                        : ages[checked ? i : ageIdx].toString(),
                                    query,
                                  });
                                }
                              }}
                              checked={
                                parseInt(query.get("minAge") ?? "1000") >= age
                              }
                              disabled={age === 0}
                            />
                          }
                          label={age === 0 ? "None provided" : `${age}+`}
                          key={age}
                        />
                      ))}
                    </FormGroup>
                  </Stack>
                </Stack>
              )}
            </CustomAccordionDetails>
          </CustomAccordion>
        </Stack>
        <AIInfo />
      </Stack>
    </SwipeableDrawer>
  );
};

const AIInfo = () => {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  return (
    <Stack
      sx={{
        marginTop: "auto",
        alignItems: "flex-start",
        padding: 1,
        color: MaterialTheme.palette.grey[600],
      }}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={0.5}
        onClick={() => setExpanded((e) => !e)}
        sx={{ cursor: "pointer" }}
      >
        <IconButton>
          <InfoRounded />
        </IconButton>
        <Typography
          sx={{
            textDecoration: "underline",
          }}
        >
          Disclaimer
        </Typography>
      </Stack>

      <Box
        sx={{
          color: MaterialTheme.palette.grey[600],
          backgroundColor: "white",
          borderRadius: "10px",
          maxHeight: expanded ? "500px" : "0px",
          overflow: "hidden",
          transition: "max-height 0.3s",
          boxSizing: "border-box",
        }}
        fontSize={"1rem"}
      >
        <Box
          sx={{
            margin: 1,
          }}
        >
          We rely on generative AI and large language models to 
          convert data found on nonprofit websites into succinctly-summarized 
          volunteer opportunities. Please be aware that our results may not be 
          100% accurate, and we encourage you to verify the information before 
          taking any action.
        </Box>
      </Box>
    </Stack>
  );
};

export default Filter;

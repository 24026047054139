import React, { useEffect, useState } from "react";
import { EventData, UserCoordinates } from "../data/type";
import SearchResult from "./SearchResult";
import { NoResults } from "./NoResults";
import InfiniteScroll from "react-infinite-scroll-component";
import Separator from "./Separator";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import useSWR from "swr";
import { useResults } from "../hooks/useResults";
import MaterialTheme from "./MaterialTheme";
import { VO } from "../utils/fetchData";

interface SearchResultListProps {
  queryKey: () => string;
}

type SortVal = "distance" | "relevance" | "alphabetical";

/**
 * Display of search results only. Business logic for fetching data
 * is handled by the parent component, making state management easier.
 */
const SearchResultList: React.FC<SearchResultListProps> = ({ queryKey }) => {
  const isMobile = useMediaQuery(MaterialTheme.breakpoints.down("md"));
  const { data, isLoading, error } = useResults(queryKey());
  const [sort, setSort] = useState<SortVal>("distance");
  const [page, setPage] = useState(1);

  const results = data?.results ?? [];

  useEffect(() => {
    if (data && data.query.keywords) {
      setSort("relevance");
    }
    setPage(1);
  }, [data]);

  const sortFn = (a: VO, b: VO) => {
    switch (sort) {
      case "distance":
        const aMinDistance = Math.min(...a.locations.map((l) => l.distance));
        const bMinDistance = Math.min(...b.locations.map((l) => l.distance));
        return aMinDistance - bMinDistance;
      case "relevance":
        return b.similarity - a.similarity;
      case "alphabetical":
        return a.org_name.localeCompare(b.org_name);
      default:
        return 0;
    }
  };

  return (
    <div style={{ overflow: "auto", zIndex: -4 }}>
      <Box
        sx={{
          mb: 2,
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          paddingX: { xs: 2, sm: 0 },
          paddingTop: { xs: 1, sm: 0 },
          boxSizing: "border-box",
        }}
      >
        <Typography color={(theme) => theme.palette.grey["600"]}>
          {isLoading || !data
            ? "Loading..."
            : `Showing ${results.length} (out of ${data.count})`}
        </Typography>
        <Box
          sx={{
            minWidth: 120,
            display: "flex",
          }}
        >
          <Typography pr={2} color={(theme) => theme.palette.grey["600"]}>
            Sort by:
          </Typography>
          <NativeSelect
            inputProps={{
              name: "sort",
              id: "sort-by",
            }}
            value={sort}
            size={"small" as "small"}
            onChange={(e) => {
              setSort(e.target.value as SortVal);
            }}
          >
            <option value={"distance"}>Distance</option>
            {data?.query.keywords && (
              <option value={"relevance"}>Relevance</option>
            )}
            <option value={"alphabetical"}>Org. Name</option>
          </NativeSelect>
        </Box>
      </Box>
      <Box
        // Padding here prevents box shadow horizontal clipping on search result card
        sx={{
          padding: "4px",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {results
          .sort(sortFn)
          .slice(page * 20 - 20, page * 20)
          .map((result) => (
            <SearchResult vo={result} key={result.id} />
          ))}
        {!isLoading && results.length === 0 ? <NoResults /> : null}
        <Pagination
          count={Math.ceil(results.length / 20)}
          page={page}
          size={"large" as "large"}
          onChange={(e, p) => setPage(p)}
          sx={{
            marginTop: 2,
            marginBottom: isMobile ? 10 : 0,
          }}
        />
      </Box>
    </div>
  );
};

export default SearchResultList;

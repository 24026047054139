import { Endpoints } from "../data/endpoints";
import { SearchRes } from "../utils/fetchData";
import useSWR, { KeyedMutator } from "swr";
import { User } from "../utils/auth";
import { useSession } from "@supabase/auth-helpers-react";

export const useResults = (query: string) => {
  return useSWR<SearchRes>("/search" + query, {
    refreshInterval: 0,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
};

export const useMe = () => {
  // const session = useSession();
  // const {mutate} = useSWR<User | null>("/me", {
  //   fallbackData: session
  //     ? {
  //         id: session.user.id,
  //         email: session.user.email!,
  //         name: session.user.user_metadata.full_name ?? null,
  //         saved: [],
  //       }
  //     : null,
  // });
  return {
    data: null as User | null,
    error: null,
    isLoading: false,
    mutate: ((v: User | null) => {}) as KeyedMutator<User | null>,
  };
};

import { set, get, del, keys } from 'idb-keyval';
import { useState } from 'react';
import { UserCoordinates } from "../data/type";

/**
 * 
 * Custom hook to cache user coordinates to IndexedDB. Uses idb-keyval for simplicity.
 * The last used coordinates are stored in memory to avoid unnecessary reads from IndexedDB.
 * 
 * https://github.com/jakearchibald/idb-keyval
 * 
 * https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 * 
 */
export const useCoordinatesCache = ({ maxSize }: { maxSize: number }) => {
    const [cache, setCache] = useState<Map<string, UserCoordinates>>(new Map());
  
    const setCoordinatesCache = async (inputLocation: string, userCoordinates: UserCoordinates) => {
      const currentKeys = await keys();
      if (currentKeys.length >= maxSize) {
        // Delete the oldest item if the maxSize is reached
        await del(currentKeys[0]);
        const newCache = new Map(cache);
        newCache.delete(currentKeys[0] as string);
        setCache(newCache);
      }
      await set(inputLocation.toLowerCase(), userCoordinates);
      setCache(new Map(cache.set(inputLocation.toLowerCase(), userCoordinates)));
    };
  
    const getCoordinatesCache = async (inputLocation: string) => {
      let coordinates = cache.get(inputLocation.toLowerCase());
      if (!coordinates) {
        coordinates = await get<UserCoordinates>(inputLocation.toLowerCase());
        if (coordinates) {
          setCache(new Map(cache.set(inputLocation.toLowerCase(), coordinates)));
        }
      }
      return coordinates;
    };
  
    const deleteCoordinatesCache = async (inputLocation: string) => {
      await del(inputLocation.toLowerCase());
      const newCache = new Map(cache);
      newCache.delete(inputLocation.toLowerCase());
      setCache(newCache);
    };
  
    return { setCoordinatesCache, getCoordinatesCache, deleteCoordinatesCache };
  };

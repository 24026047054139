import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import People from "./pages/People";
import Search from "./pages/Search";
import Navbar from "./components/navigation-bar";
import Footer from "./components/Footer";
import Donate from "./pages/Donate";
import Contact from "./pages/Contact";
import JoinUs from "./pages/JoinUs";
import Zeffy from "./pages/Zeffy";
import { StrictMode } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./components/MaterialTheme";
import { SWRConfig } from "swr";
import { fetcher } from "./utils/fetchData";
import Login from "./pages/Login";
import { SessionContextProvider } from "@supabase/auth-helpers-react";
import { supabase } from "./utils/auth";
import { Account } from "./pages/Account";
import { AuthCallback } from "./pages/AuthCallback";

const App = () => {
  return (
    <StrictMode>
      <ThemeProvider theme={theme}>
        <SessionContextProvider supabaseClient={supabase}>
          <SWRConfig
            value={{
              fetcher: (resource, init) => fetcher("GET", resource, init),
            }}
          >
            <BrowserRouter>
              <Navbar />

              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/people" element={<People />} />
                {/*<Route path="/login" element={<Login />} />*/}
                {/*<Route path="/callback" element={<AuthCallback />} />*/}
                {/*<Route path="/account" element={<Account />} />*/}
                <Route path="/search" element={<Search />} />
                <Route path="/donate" element={<Donate />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/joinus" element={<JoinUs />} />
                <Route path="/zeffy" element={<Zeffy />} />
                <Route path="*" element={<NotFound />} />
              </Routes>

              <Footer />
            </BrowserRouter>
          </SWRConfig>
        </SessionContextProvider>
      </ThemeProvider>
    </StrictMode>
  );
};

export default App;

import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import Button from './button';
import { isFieldValid } from '../utils/input-validation';
import emailjs from '@emailjs/browser';
import FeedbackModal from './feedback-modal';
import { EmailJSConfig, ReCaptchaConfig } from '../data/configuration';
import ReCAPTCHA from 'react-google-recaptcha';

type FormData = {
  firstName: string;
  lastName: string;
  purpose: string;
  emailAddress: string;
  phoneNumber: string;
  message: string;
  yesToReceiveNotification: boolean;
  yetToDiscussDonation: boolean;
};

const DonateContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    purpose: 'I want to donate',
    emailAddress: '',
    phoneNumber: '',
    message: '',
    yesToReceiveNotification: false,
    yetToDiscussDonation: false,
  });

  const [captcha, setCaptcha] = useState<string | null>(null);

  const handleCaptchaChange = (value: string | null) => {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      captcha: { result: false, message: '' },
    }));
    setCaptcha(value);
  };

  // Handle checkbox input
  const handleYesToReceiveNotification = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      yesToReceiveNotification: checked,
    }));
  };

  // Handle other common inputs
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Clear the error state when the user starts typing
    if (event.target.name === 'firstName') {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        firstName: { result: false, message: '' },
      }));
    } else if (event.target.name === 'lastName') {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        lastName: { result: false, message: '' },
      }));
    } else if (event.target.name === 'emailAddress') {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        emailAddress: { result: false, message: '' },
      }));
    } else if (event.target.name === 'phoneNumber') {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: { result: false, message: '' },
      }));
    }

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      captcha: { result: false, message: '' },
    }));

    setFormData((prevData) => ({
      ...prevData,
      [event.target.name]: event.target.value,
    }));
  };

  // Handle the FeedbackModal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Handle errors and error message for each input
  const [formErrors, setFormErrors] = useState({
    firstName: { result: false, message: '' },
    lastName: { result: false, message: '' },
    emailAddress: { result: false, message: '' },
    phoneNumber: { result: false, message: '' },
    captcha: { result: false, message: '' },
  });

  const resetForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      purpose: 'I want to donate',
      emailAddress: '',
      phoneNumber: '',
      message: '',
      yesToReceiveNotification: false,
      yetToDiscussDonation: false,
    });
    setCaptcha(null);
  };

  const handleSubmit = () => {
    const updatedFormErrors: {
      [key: string]: any;
    } = { ...formErrors };

    // Define the fields to validate and whether they are required
    const fieldsToValidate = {
      firstName: true,
      lastName: true,
      emailAddress: true,
      phoneNumber: false,
    };

    Object.entries(fieldsToValidate).forEach(([field, isRequired]) => {
      let value = formData[field as keyof FormData];
      let resultObj = isFieldValid(field, value, isRequired);
      resultObj.result = !resultObj.result;
      updatedFormErrors[field] = resultObj;
    });

    updatedFormErrors.captcha = {
      result: captcha === null,
    };

    // Update the formErrors state with the values from updatedFormErrors
    setFormErrors({
      firstName: updatedFormErrors.firstName,
      lastName: updatedFormErrors.lastName,
      emailAddress: updatedFormErrors.emailAddress,
      phoneNumber: updatedFormErrors.phoneNumber,
      captcha: updatedFormErrors.captcha,
    });

    // Check if all values in updatedFormErrors are 'false'
    const noErrors = Object.values(updatedFormErrors).every(
      (error) => error.result === false
    );

    // Open the modal only if there are no errors
    if (noErrors) {
      const formDataWithToken = {
        ...formData,
        'g-recaptcha-response': captcha,
      };
      emailjs
        .send(
          EmailJSConfig.serviceId,
          EmailJSConfig.template1Id,
          formDataWithToken,
          EmailJSConfig.publicKey
        )
        .then(
          (result) => {
            console.log(result.text);
            resetForm();
          },
          (error) => {
            console.log(error.text);
          }
        );

      openModal();
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' as ScrollBehavior });
  }, []);

  return (
    <>
      <FeedbackModal
        open={isModalOpen}
        title='Thank you for your interest. We will be in touch with you soon!'
        content=''
        image={'jumping-dog.png'}
        onClose={closeModal}
      ></FeedbackModal>
      <Stack spacing={2}>
        <Box boxShadow={2} p={4} borderRadius={2}>
          <Stack spacing={2}>
            <Stack>
              <Typography variant='h3'>
                Contact us to hear about upcoming events or to schedule a
                conversation about making an impact with your generosity.
              </Typography>
            </Stack>
            {/* First Name and Last Name */}
            <Stack
              direction={{
                xl: 'row',
                lg: 'row',
                md: 'row',
                sm: 'row',
                xs: 'column',
              }}
              spacing={{ xs: 2, sm: 4, md: 4, lg: 4, xl: 4 }}
            >
              <Stack
                width={{
                  xs: '100%',
                  sm: '50%',
                }}
                spacing={1}
              >
                <Typography variant='body2'>First Name*</Typography>
                <TextField
                  placeholder='Smith'
                  fullWidth
                  required
                  variant='outlined'
                  name='firstName'
                  value={formData.firstName}
                  onChange={handleChange}
                  size='small'
                  error={formErrors.firstName.result}
                  helperText={
                    formErrors.firstName.result && formErrors.firstName.message
                  }
                />
              </Stack>
              <Stack width={{ xs: '100%', sm: '50%' }} spacing={1}>
                <Typography variant='body2'>Last Name*</Typography>
                <TextField
                  placeholder='Davidson'
                  fullWidth
                  required
                  variant='outlined'
                  name='lastName'
                  value={formData.lastName}
                  onChange={handleChange}
                  size='small'
                  error={formErrors.lastName.result}
                  helperText={
                    formErrors.lastName.result && formErrors.lastName.message
                  }
                />
              </Stack>
            </Stack>
            <Stack width={'100%'} spacing={1}>
              <Typography variant='body2'>Email Address*</Typography>
              <TextField
                placeholder='info@youremail.com'
                fullWidth
                required
                variant='outlined'
                name='emailAddress'
                value={formData.emailAddress}
                onChange={handleChange}
                size='small'
                error={formErrors.emailAddress.result}
                helperText={
                  formErrors.emailAddress.result &&
                  formErrors.emailAddress.message
                }
              />
            </Stack>
            <Stack width={'100%'} spacing={1}>
              <Typography variant='body2'>Phone Number</Typography>
              <TextField
                placeholder='xxx-xxx-xxxx'
                fullWidth
                variant='outlined'
                name='phoneNumber'
                value={formData.phoneNumber}
                onChange={handleChange}
                size='small'
                error={formErrors.phoneNumber.result}
                helperText={
                  formErrors.phoneNumber.result &&
                  formErrors.phoneNumber.message
                }
              />
            </Stack>
            <Stack width={'100%'} spacing={1}>
              <Typography variant='body2'>Message</Typography>
              <TextField
                placeholder='Write your message to us here'
                fullWidth
                multiline
                rows={4}
                sx={{ my: 2 }}
                name='message'
                value={formData.message}
                onChange={handleChange}
                size='small'
              />
            </Stack>
            <Stack>
              <FormControlLabel
                control={<Checkbox size='small' />}
                label='Yes, send me information on your next fundraising event'
                checked={formData.yesToReceiveNotification}
                onChange={handleYesToReceiveNotification}
              />
            </Stack>
            <ReCAPTCHA
              size='compact'
              sitekey={ReCaptchaConfig.publicKey}
              onChange={handleCaptchaChange}
            />
            <Stack direction={'row'} spacing={4} alignItems={'center'}>
              <Button
                size='large'
                bgStyle='primary'
                hoverStyle='dark'
                onClick={handleSubmit}
              >
                <Typography variant='h3' fontWeight={'bold'} align='center'>
                  SEND
                </Typography>
              </Button>
              {formErrors.firstName.result ||
              formErrors.lastName.result ||
              formErrors.emailAddress.result ||
              formErrors.phoneNumber.result ? (
                <Typography variant='body1' color='error.main'>
                  Please make sure all fields are correctly filled.
                </Typography>
              ) : formErrors.captcha.result ? (
                <Typography variant='body1' color='error.main'>
                  Please make sure bot validation is met.
                </Typography>
              ) : (
                <></>
              )}
            </Stack>
          </Stack>
        </Box>
        <Typography variant='body1' color={'grey.A400'}>
          * Your donation is 100% tax-deductible and will be applied exclusively
          to covering our technology-related expenses that are essential to
          building and maintaining the Scout Platform. You will receive all
          relevant paperwork to confirm your donation.
        </Typography>
      </Stack>
    </>
  );
};

export default DonateContactForm;

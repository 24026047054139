import {
  Grid,
  TextField,
  Typography,
  ButtonBase,
  Tooltip,
  Input,
  IconButton,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ButtonComp from "./button";
import { SearchInput } from "../data/type";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { DEFAULT_DISTANCE } from "./filter";
import Box from "@mui/material/Box";
import Button from "./button";
import Autocomplete from "react-google-autocomplete";
import { MyLocation } from "@mui/icons-material";

interface SearchBarProps {
  handleSearchInput: (searchInput: SearchInput) => void;
  location: string;
  setLocation: (location: string) => void;
}

export const SearchBar = () => {
  const [searchParams] = useSearchParams();

  const [keywords, setKeywords] = useState("");
  const [location, setLocation] = useState("");

  const searchButtonRef = React.useRef<HTMLButtonElement | null>(null);

  const navigate = useNavigate();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    const keywordsParam = searchParams.get("keywords");
    setKeywords(keywordsParam ?? "");

    const locationParam = searchParams.get("location");
    if (window.location.href.includes("/search")) {
      setLocation(locationParam ?? "Springfield, NJ");
    }
  }, [searchParams]);

  const handleSearch = () => {
    if (!location) return;
    // Easy to use the navigate function from react-router-dom
    // since it's already available to us. It will correctly
    // parse the query params and navigate to the correct page.
    navigate(
      `/search?${createSearchParams({
        keywords,
        location,
        distance: `${DEFAULT_DISTANCE}`,
      })}`,
    );
  };

  return (
    <Box width={"100%"}>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        bgcolor="primary.light"
        sx={{ padding: "1rem 1rem 2rem 1rem", marginTop: "0" }}
      >
        <Grid item xs={12} sm={6} md={5} lg={4} xl={4}>
          <TextField
            variant="outlined"
            placeholder="✨ What would you like to do?"
            fullWidth={true}
            sx={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              input: { color: "black" },
            }}
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={3} xl={2.5} position={"relative"}>
          <TextField
            variant="outlined"
            placeholder="Location (*)"
            fullWidth={true}
            sx={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              input: { color: "black" },
              fontStyle: location.startsWith("CURLOC:") ? "italic" : "normal",
            }}
            value={
              location.startsWith("CURLOC:") ? "Current Location" : location
            }
            onChange={(e) => setLocation(e.target.value)}
            onKeyDown={handleKeyDown}
            onFocus={(e) => {
              if (location.startsWith("CURLOC:")) {
                setLocation("");
              }
            }}
            inputProps={{
              sx: {
                marginRight: "40px",
              },
            }}
            // InputProps={{
            //   inputComponent: ({ inputRef, onFocus, onBlur, ...props }) => (
            //     <Autocomplete
            //       apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            //       {...props}
            //       onPlaceSelected={(selected) => {
            //         console.log(selected);
            //       }}
            //     />
            //   ),
            // }}
          />
          <Stack
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              paddingTop: 0,
              marginTop: "8px",
              marginRight: "6px",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                if ("geolocation" in navigator) {
                  console.log("Clicked", navigator, window.navigator);
                  navigator.geolocation.getCurrentPosition((position) => {
                    console.log("Position: ", position);
                    setLocation(
                      `CURLOC:${position.coords.latitude},${position.coords.longitude}`,
                    );
                  }, console.error);
                }
              }}
            >
              <MyLocation />
            </IconButton>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2} xl={1.2}>
          <Tooltip title={location === "" ? "Please enter a location" : ""}>
            <div>
              <Button
                size="large"
                bgStyle="secondary"
                hoverStyle="light"
                width="100%"
                onClick={handleSearch}
                disabled={location === ""}
              >
                <strong>Search</strong>
              </Button>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );
};

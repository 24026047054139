import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import FeedbackModal from './feedback-modal';
import { isFieldValid } from '../utils/input-validation';
import emailjs from '@emailjs/browser';
import Button from './button';
import { EmailJSConfig, ReCaptchaConfig } from '../data/configuration';
import ReCAPTCHA from 'react-google-recaptcha';

type FormData = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  message: string;
  positions: string[];
};

const JoinTheTeamForm = () => {
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    message: '',
    positions: [],
  });
  const [positions, setPositions] = useState<string[]>([]);

  const [captcha, setCaptcha] = useState<string | null>(null);

  const handleCaptchaChange = (value: string | null) => {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      captcha: { result: false, message: '' },
    }));
    setCaptcha(value);
  };

  // For operating the modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Add error states and helper texts for each text field
  const [formErrors, setFormErrors] = useState({
    firstName: { result: false, message: '' },
    lastName: { result: false, message: '' },
    emailAddress: { result: false, message: '' },
    phoneNumber: { result: false, message: '' },
    captcha: { result: false, message: '' },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    // Clear the error state when the user starts typing
    if (name === 'firstName') {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        firstName: { result: false, message: '' },
      }));
    } else if (name === 'lastName') {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        lastName: { result: false, message: '' },
      }));
    } else if (name === 'emailAddress') {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        emailAddress: { result: false, message: '' },
      }));
    } else if (name === 'phoneNumber') {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: { result: false, message: '' },
      }));
    }

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      captcha: { result: false, message: '' },
    }));

    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    // Create a copy of the positions array
    const updatedPositions = [...positions];
    // If the checkbox is checked, add the position to the array; otherwise, remove it
    if (checked) {
      updatedPositions.push(name);
    } else {
      const index = updatedPositions.indexOf(name);
      if (index !== -1) {
        updatedPositions.splice(index, 1);
      }
    }
    setPositions(updatedPositions);

    setFormData((prevData) => ({
      ...prevData,
      positions: updatedPositions,
    }));
  };

  const resetForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
      message: '',
      positions: [],
    });
    setCaptcha(null);
    setPositions([]);
  };

  const handleSubmit = () => {
    setFormData((prevData) => ({
      ...prevData,
      positions: positions,
    }));

    const updatedFormErrors: {
      [key: string]: any;
    } = { ...formErrors };

    const fieldsToValidate = {
      firstName: true,
      lastName: true,
      emailAddress: true,
      phoneNumber: false,
    };

    Object.entries(fieldsToValidate).forEach(([field, isRequired]) => {
      let value = formData[field as keyof FormData];
      let resultObj = isFieldValid(field, value, isRequired);
      resultObj.result = !resultObj.result;
      updatedFormErrors[field] = resultObj;
    });

    updatedFormErrors.captcha = {
      result: captcha === null,
    };

    // Update the formErrors state with the values from updatedFormErrors
    setFormErrors({
      firstName: updatedFormErrors.firstName,
      lastName: updatedFormErrors.lastName,
      emailAddress: updatedFormErrors.emailAddress,
      phoneNumber: updatedFormErrors.phoneNumber,
      captcha: updatedFormErrors.captcha,
    });

    // Check if all values in updatedFormErrors are 'false'
    const noErrors = Object.values(updatedFormErrors).every(
      (error) => error.result === false
    );

    // Open the modal only if there are no errors
    if (noErrors) {
      const formDataWithToken = {
        ...formData,
        'g-recaptcha-response': captcha,
      };
      emailjs
        .send(
          EmailJSConfig.serviceId,
          EmailJSConfig.template2Id,
          formDataWithToken,
          EmailJSConfig.publicKey
        )
        .then(
          (result) => {
            console.log(result.text);
            resetForm();
          },
          (error) => {
            console.log(error.text);
          }
        );

      openModal();
    }
  };

  return (
    <>
      <FeedbackModal
        open={isModalOpen}
        title='Thank you for your interest. We will be in touch with you soon!'
        content=''
        image={'jumping-dog.png'}
        onClose={closeModal}
      ></FeedbackModal>
      <Stack alignItems={'center'}>
        <Stack
          width={{
            xl: '800px',
            lg: '800px',
            md: '800px',
            sm: '570px',
            xs: '95%',
          }}
          boxShadow={2}
          p={4}
          borderRadius={2}
          bgcolor={'white'}
        >
          <Stack spacing={2}>
            {/* First Name and Last Name */}
            <Stack
              direction={{
                xl: 'row',
                lg: 'row',
                md: 'row',
                sm: 'row',
                xs: 'column',
              }}
              spacing={{ xs: 2, sm: 4, md: 4, lg: 4, xl: 4 }}
            >
              <Stack
                width={{
                  xs: '100%',
                  sm: '50%',
                }}
                spacing={1}
              >
                <Typography variant='body2'>First Name*</Typography>
                <TextField
                  placeholder='Smith'
                  fullWidth
                  required
                  variant='outlined'
                  name='firstName'
                  value={formData.firstName}
                  onChange={handleChange}
                  size='small'
                  error={formErrors.firstName.result}
                  helperText={
                    formErrors.firstName.result && formErrors.firstName.message
                  }
                />
              </Stack>
              <Stack width={{ xs: '100%', sm: '50%' }} spacing={1}>
                <Typography variant='body2'>Last Name*</Typography>
                <TextField
                  placeholder='Davidson'
                  fullWidth
                  required
                  variant='outlined'
                  name='lastName'
                  value={formData.lastName}
                  onChange={handleChange}
                  size='small'
                  error={formErrors.lastName.result}
                  helperText={
                    formErrors.lastName.result && formErrors.lastName.message
                  }
                />
              </Stack>
            </Stack>
            {/* Email Address and Phone Number  */}
            <Stack
              direction={{
                xl: 'row',
                lg: 'row',
                md: 'row',
                sm: 'row',
                xs: 'column',
              }}
              spacing={{ xs: 2, sm: 4, md: 4, lg: 4, xl: 4 }}
            >
              <Stack
                width={{
                  xs: '100%',
                  sm: '50%',
                }}
                spacing={1}
              >
                <Typography variant='body2'>Email Address*</Typography>
                <TextField
                  placeholder='info@youremail.com'
                  fullWidth
                  required
                  variant='outlined'
                  name='emailAddress'
                  value={formData.emailAddress}
                  onChange={handleChange}
                  size='small'
                  error={formErrors.emailAddress.result}
                  helperText={
                    formErrors.emailAddress.result &&
                    formErrors.emailAddress.message
                  }
                />
              </Stack>
              <Stack width={{ xs: '100%', sm: '50%' }} spacing={1}>
                <Typography variant='body2'>Phone Number</Typography>
                <TextField
                  placeholder='xxx-xxx-xxxx'
                  fullWidth
                  variant='outlined'
                  name='phoneNumber'
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  size='small'
                  error={formErrors.phoneNumber.result}
                  helperText={
                    formErrors.phoneNumber.result &&
                    formErrors.phoneNumber.message
                  }
                />
              </Stack>
            </Stack>

            {/* Positions  */}
            <Stack width={'100%'} spacing={1}>
              <Typography variant='body2' fontWeight={'bold'}>
                How would you like to help Samaritan Scout to grow and thrive?
              </Typography>
            </Stack>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    name='Search / Web Crawling Development'
                    onChange={handleCheckboxChange}
                    checked={positions.includes(
                      'Search / Web Crawling Development'
                    )}
                  />
                }
                label='Search / Web Crawling Development'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    name='Front End Social Platform Development'
                    onChange={handleCheckboxChange}
                    checked={positions.includes(
                      'Front End Social Platform Development'
                    )}
                  />
                }
                label='Front End Social Platform Development'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    name='Nonprofit Organization Relationship Building'
                    onChange={handleCheckboxChange}
                    checked={positions.includes(
                      'Nonprofit Organization Relationship Building'
                    )}
                  />
                }
                label='Nonprofit Organization Relationship Building'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    name='Website Management'
                    onChange={handleCheckboxChange}
                    checked={positions.includes('Website Management')}
                  />
                }
                label='Website Management'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    name='Strategy'
                    onChange={handleCheckboxChange}
                    checked={positions.includes('Strategy')}
                  />
                }
                label='Strategy'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    name='Fundraising'
                    onChange={handleCheckboxChange}
                    checked={positions.includes('Fundraising')}
                  />
                }
                label='Fundraising'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    name='PR and Marketing'
                    onChange={handleCheckboxChange}
                    checked={positions.includes('PR and Marketing')}
                  />
                }
                label='PR and Marketing'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    name='Other'
                    onChange={handleCheckboxChange}
                    checked={positions.includes('Other')}
                  />
                }
                label='Other'
              />
            </FormGroup>

            {/* Message */}
            <Stack width={'100%'} spacing={1}>
              <Typography variant='body2' fontWeight={'bold'}>
                Message
              </Typography>
              <TextField
                placeholder='Share your thoughts and inspirations with us'
                fullWidth
                multiline
                rows={4}
                sx={{ my: 2 }}
                name='message'
                value={formData.message}
                onChange={handleChange}
                size='small'
              />
            </Stack>

            <ReCAPTCHA
              size='compact'
              sitekey={ReCaptchaConfig.publicKey}
              onChange={handleCaptchaChange}
            />

            <Stack direction={'row'} spacing={4} alignItems={'center'}>
              <Button
                onClick={handleSubmit}
                size='large'
                bgStyle='primary'
                hoverStyle='dark'
              >
                <Typography
                  variant='body1'
                  fontSize={'20px'}
                  fontWeight={'bold'}
                >
                  Send
                </Typography>
              </Button>
              {formErrors.firstName.result ||
              formErrors.lastName.result ||
              formErrors.emailAddress.result ||
              formErrors.phoneNumber.result ? (
                <Typography variant='body1' color='error.main'>
                  Please make sure all fields are correctly filled.
                </Typography>
              ) : formErrors.captcha.result ? (
                <Typography variant='body1' color='error.main'>
                  Please make sure bot validation is met.
                </Typography>
              ) : (
                <></>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default JoinTheTeamForm;

import { createClient } from "@supabase/supabase-js";
import { useUser as useSupabaseUser } from "@supabase/auth-helpers-react";
import { useQuery } from "@supabase-cache-helpers/postgrest-swr";
import { VO } from "./fetchData";

export const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL as string,
  process.env.REACT_APP_SUPABASE_ANON_KEY as string,
);

export type User = {
  id: string;
  email: string;
  name: string;
  saved: (Omit<VO, "similarity" | "distance"> & { created_at: string })[];
};

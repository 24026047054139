import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";

import { styled } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  IconButton,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import MaterialTheme from "./MaterialTheme";
import { useMe } from "../hooks/useResults";

const StyledTypography = styled(Typography)`
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
`;

function Navbar() {
  // Used for opening and cloing narbar menu on mobile device
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const { data: me } = useMe();
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      setIsDrawerOpen(open);
    };

  // Get breakpoints for responsive design
  const theme = MaterialTheme;
  const isMobile = useMediaQuery(MaterialTheme.breakpoints.down("md"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isXl = useMediaQuery(theme.breakpoints.only("xl"));

  // Navbar menu items and links
  let menuItems = [
    { label: "Search", link: "/search" },
    { label: "About", link: "/about" },
    { label: "People", link: "/people" },
    { label: "Donate", link: "/donate", primary: false },
    // {
    //   label: me ? "My Account" : "Login",
    //   link: me ? "/account" : "/login",
    //   primary: true,
    // },
  ];

  if (isMobile) {
    menuItems = [{ label: "Home", link: "/" }, ...menuItems];
  }

  // Get current route for indicating navbar items
  const location = useLocation();
  const isActiveRoute = (route: string) => {
    return location.pathname === route;
  };

  const isMobileSearch = location.pathname === "/search" && isMobile;

  return (
    <AppBar
      position={isMobileSearch ? "static" : "sticky"}
      sx={{
        top: 0,
        zIndex: MaterialTheme.zIndex.appBar,
        backgroundColor: "primary",
      }}
    >
      <Container maxWidth="xl" sx={{ height: 75, alignItems: "center" }}>
        {isXl || isLg || isMd ? (
          <Stack
            direction="row"
            spacing={2}
            sx={{
              height: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack direction="row" spacing={2} alignItems={"center"}>
              <Link to="/" style={{ textDecoration: "none" }}>
                <img
                  src={"/images/lacy-dog-square.jpg"}
                  alt="logo"
                  style={{
                    display: "block",
                    height: "50px",
                    width: "auto",
                    borderRadius: "50%",
                  }}
                />
              </Link>

              <Link to="/" style={{ textDecoration: "none" }}>
                <Typography
                  variant="h6"
                  fontWeight={"bold"}
                  noWrap
                  color="secondary"
                  textTransform={"uppercase"}
                  letterSpacing={2}
                >
                  Samaritan Scout
                </Typography>
              </Link>
            </Stack>

            <Stack
              direction="row"
              spacing={2}
              sx={{ justifyContent: "flex-end" }}
            >
              {menuItems.map((item) => (
                <Link
                  key={item.label}
                  to={item.link}
                  style={{ textDecoration: "none" }}
                >
                  <StyledTypography
                    noWrap
                    paddingY={"5px"}
                    sx={{
                      color: item?.primary
                        ? "secondary.main"
                        : isActiveRoute(item.link)
                          ? "primary.main"
                          : "white",
                      bgcolor: isActiveRoute(item.link)
                        ? "primary.light"
                        : "transparent",
                      "&:hover": {
                        color: "primary.main",
                        bgcolor: "white",
                        transition:
                          "background-color 0.3s ease-out, color 0.3s ease-out",
                      },
                    }}
                  >
                    {item.label}
                  </StyledTypography>
                </Link>
              ))}
            </Stack>
          </Stack>
        ) : (
          // Mobile view
          <Stack
            direction="row"
            spacing={2}
            sx={{
              height: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack direction={"row"} alignItems={"center"}>
              <Link to="/" style={{ textDecoration: "none" }}>
                <img
                  src={"/images/lacy-dog-square.jpg"}
                  alt="logo"
                  style={{
                    display: "block",
                    height: "40px",
                    width: "auto",
                    borderRadius: "50%",
                  }}
                />
              </Link>
              <Link to="/" style={{ textDecoration: "none" }}>
                <StyledTypography
                  fontWeight={"bold"}
                  noWrap
                  color="secondary"
                  textTransform={"uppercase"}
                  letterSpacing={2}
                >
                  Samaritan Scout
                </StyledTypography>
              </Link>
            </Stack>
            <Stack direction="row" alignItems="center">
              <IconButton onClick={toggleDrawer(true)}>
                <MenuIcon fontSize="medium" sx={{ color: "white" }} />
              </IconButton>
              <SwipeableDrawer
                anchor="top"
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                sx={{ zIndex: MaterialTheme.zIndex.appBar + 1 }}
              >
                <Box bgcolor="primary.main" px={2} pt={2} pb={6}>
                  <Stack>
                    <IconButton
                      onClick={toggleDrawer(false)}
                      sx={{ marginLeft: "auto", marginRight: "0" }}
                    >
                      <CloseIcon fontSize="medium" sx={{ color: "white" }} />
                    </IconButton>
                  </Stack>
                  <Stack direction="column" spacing={2} alignItems={"center"}>
                    {menuItems.map((item) => (
                      <Link
                        key={item.label}
                        to={item.link}
                        style={{ textDecoration: "none" }}
                        onClick={toggleDrawer(false)}
                      >
                        <StyledTypography
                          variant="h6"
                          paddingY={"5px"}
                          noWrap
                          sx={{
                            display: "inline",
                            color: item?.primary
                              ? "secondary.main"
                              : isActiveRoute(item.link)
                                ? "primary.main"
                                : "white",
                            bgcolor: isActiveRoute(item.link)
                              ? "primary.light"
                              : "transparent",
                            "&:hover": {
                              color: "primary.main",
                              bgcolor: "white",
                              transition:
                                "background-color 0.3s ease-out, color 0.3s ease-out",
                            },
                          }}
                        >
                          {item.label}
                        </StyledTypography>
                      </Link>
                    ))}
                  </Stack>
                </Box>
              </SwipeableDrawer>
            </Stack>
          </Stack>
        )}
      </Container>
    </AppBar>
  );
}

export default Navbar;
